import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Relations from "../components/common/relations"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/news/title"
import Contact from "../components/common/contact"
import Content from "../components/news/content"

const NewsPage = () => {
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        news {
          title
          sections {
            title
            key
            blocks {
              title
              content
            }
          }
        }
      }
    }
  `)
  const { title, sections } = pages.news

  return (
    <Layout>
      <SEO title={title} />
      <Title title={title} />
      <Content section={sections.find(s => s.key === "news-highlight")} />
      <Contact
        section={sections.find(s => s.key === "contact")}
        hidePicture={true}
      />
      <Relations section={sections.find(s => s.key === "relations")} />
    </Layout>
  )
}

export default NewsPage
