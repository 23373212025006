import React, { useContext } from "react"
import VizSensor from "react-visibility-sensor"

import { ScrollContext } from "../layout"

import "./title.scss"

const Title = ({ title }) => {
  const [, setFirstBlockVisibility] = useContext(ScrollContext)
  return (
    <VizSensor
      onChange={isVisible => setFirstBlockVisibility(isVisible)}
      partialVisibility
    >
      <div id="news-page-title">
        <div>
          <h1>{title}</h1>
        </div>
      </div>
    </VizSensor>
  )
}

export default Title
