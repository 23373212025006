import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import { useWindowWidth } from "../../services/hooks"

import "./content.scss"

import Arrow from "../../images/arrow.svg"
import variables from "../variables.scss"

const Content = ({ section }) => {
  const [nrOfVisibleNews, setNumberOfVisibleNews] = useState(6)
  const width = useWindowWidth()
  const isMobile = width <= parseInt(variables.mobile)
  const { pages } = useStaticQuery(graphql`
    query {
      pages {
        news {
          components {
            posts {
              id
              title
              description
              published_at
              thumbnail {
                url
              }
            }
          }
        }
      }
    }
  `)
  const { posts } = pages.news.components
  const moreNews = () => {
    const newNrOfVisibleNews = nrOfVisibleNews + 2
    setNumberOfVisibleNews(
      newNrOfVisibleNews < posts.length ? newNrOfVisibleNews : posts.length
    )
  }

  return (
    <section id="news-highlight">
      <div>
        <div className="left-column">
          <h3>{section.title}</h3>
        </div>
        <div className="right-column">
          <div className="news-list">
            {posts
              .sort(
                (a, b) => +new Date(b.published_at) - +new Date(a.published_at)
              )
              .slice(0, nrOfVisibleNews)
              .map((p, i) => (
                <Link key={i} to={`${p.id}`} className="news-preview">
                  {!(isMobile && i) && (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(https://www.villagemedia.hu/api${p.thumbnail.url})`,
                      }}
                    ></div>
                  )}
                  <h4>
                    <span>{p.title}</span>
                  </h4>
                  <div className="post-description">
                    <p>{p.description}</p>
                  </div>
                </Link>
              ))}
          </div>
          {nrOfVisibleNews < posts.length && (
            <div className="older-news" onClick={() => moreNews()}>
              <div>Régebbi híreink</div>
              <Arrow />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
